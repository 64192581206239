import Octo from "./octo"
import _ from "lodash"
import {
  fetchFxhashData,
  loadOctoImages,
  // rectify,
  scaleUpAll,
  shuffle,
  ease,
} from "../utils"
// import collectionDataDummy from "../../data/collectionDataDummy"

/**
 * In this sketch, I separated hover check as a function instead of having inside each object.
 * This requires setting hoverable position all manually. Not sure yet if this is better.
 * Maybe next time, check pixel (alpha === 0) to determine hoverable area.
 *
 * TODO (this version):
 * - improve playAnimArray()
 *
 * TODO (next version):
 * - keyframes array per game object
 * - generic game object class:
 *  - load sprites (single or array)
 *  - position tracking
 *  - animate sprites
 *  - maybe built-in tweening?
 */

const projectTitle = `HashOctoz-Play-BusStop`

// static assets folder
const globalImagePath = `/assets`
const localImagePath = `/assets/images-busstop` // TODO: need to use local image directory such as ./images/hotsprings
let imageData = {}

let collectionData = []

const origWidth = 220
const origHeight = 220

let pg // main offscreen graphicsd

let scaleFactor = 1
let factor = 1 // used for resizing canvas

let numOctoz = 4
let octoz = []
let octoInitialized = false
// let activeOcto = null

let dataIsLoaded = false
let saveImage = false
// let displayDialogue = false

let elapsedTime = 0
let animTime = 0

let octoPositions = [
  [84, 120],
  [110, 120],
  [136, 120],
  [122, 25],
]

//******************** sketch specific variables */
let bgImg
let bgCloudImg
let bgSunImg
let reflectionImgs = []
let frontReflectionImgs = []
let stationImg
let boatReflectionImgs = []
let boatFillImg
let boatLineImg
let buttonImg

let octoBooleans = [true, true, true] // whether to display or not

let boatX = 240
let boatY = 40
let boatTintCol
const boatTintCols = ["#ffffff", "#ffff00"]
let boatTintColIdx = 0

const buttonObj = { hoverX: 1, hoverY: 190, hoverW: 28, hoverH: 28 }

let timeMode = "sunset" // sunset or night

// animation stages
// 1. waiting
// 2. bus comes in and stop
// 3. bus leave out of screen.
// 4. shark follows.
let curAnimKey = 0
const animKeys = [
  {
    name: "waiting",
    duration: 0.5,
    boat: {
      x: 240,
    },
  },
  {
    name: "boat in",
    duration: 3.2,
    easeType: "easeOutQuint",
    boat: {
      x: 240,
    },
  },
  {
    name: "boat stop",
    duration: 0.1,
    boat: {
      x: 20,
    },
  },
  {
    name: "boat out",
    duration: 1.5,
    easeType: "easeInQuint",
    boat: {
      x: 20,
    },
  },
  {
    name: "shark in",
    duration: 2.5,
    boat: {
      x: -220,
    },
  },
  {
    name: "shark out",
    duration: 2.5,
    boat: {
      x: -220,
    },
  },
]

export default function BirthSketch(p) {
  let state = {}

  p.updateWithProps = props => {
    // state = Object.assign(state, props)
  }

  p.preload = preload(p)
  p.setup = setup(p, state)
  p.draw = draw(p)

  p.mouseReleased = mouseReleased(p)
  p.keyTyped = keyTyped(p)
  p.windowResized = windowResized(p, state)

  p.touchStarted = touchStarted(p)
  p.touchMoved = touchMoved(p)
  p.touchEnded = touchEnded(p)
}

function preload(p) {
  return () => {
    // collectionData = collectionDataDummy
    // dataIsLoaded = true
    // collectionData = shuffle(collectionData)
    // console.log("collectionData:", collectionData)

    fetchFxhashData(5164).then(r => {
      collectionData = r.data.generativeToken.entireCollection
      // remove any invalid data
      let remove = []
      for (let i = 0; i < collectionData.length; i++) {
        if (!collectionData[i].metadata.attributes) {
          remove.push(i)
        }
      }
      for (let i = remove.length - 1; i >= 0; i--) {
        collectionData.splice(remove[i], 1)
      }
      collectionData = shuffle(collectionData)
      dataIsLoaded = true

      console.log("collectionData:", collectionData)
    })
    // load octo images
    imageData = loadOctoImages(p, globalImagePath)
    // load "play" specific images
    bgImg = p.loadImage(`${localImagePath}/bg.png`)
    bgCloudImg = p.loadImage(`${localImagePath}/bg-cloud.png`)
    bgSunImg = p.loadImage(`${localImagePath}/bg-sun.png`)
    for (let i = 0; i < 4; i++) {
      reflectionImgs.push(
        p.loadImage(`${localImagePath}/reflection-${p.nf(i + 1, 2)}.png`)
      )
      frontReflectionImgs.push(
        p.loadImage(`${localImagePath}/front-reflection-${p.nf(i + 1, 2)}.png`)
      )
    }
    for (let i = 0; i < 5; i++) {
      boatReflectionImgs.push(
        p.loadImage(`${localImagePath}/boat-reflection-${p.nf(i + 1, 2)}.png`)
      )
    }
    stationImg = p.loadImage(`${localImagePath}/station.png`)
    boatFillImg = p.loadImage(`${localImagePath}/boat-fill.png`)
    boatLineImg = p.loadImage(`${localImagePath}/boat-line.png`)
    buttonImg = p.loadImage(`${localImagePath}/button.png`)
  }
}

function setup(p, props) {
  return () => {
    // const dim = calculateCanvasSize(p, factor, origWidth, origHeight)
    const dim = calculateCanvasSize(
      p,
      origWidth,
      origHeight,
      p.windowWidth,
      p.windowHeight
    )
    p.createCanvas(dim.w, dim.h)

    p.frameRate(30)
    // p.pixelDensity(1) // REVIEW: this doesn't matter?
    p.noSmooth()
    p.textFont("PressStart2P")

    pg = p.createGraphics(origWidth * scaleFactor, origHeight * scaleFactor)
    pg.pixelDensity(1)
    // pg.colorMode(HSB, 360, 100, 100, 100);
    pg.textFont("PressStart2P")
    pg.textSize(8)
  }
}

function getObjectsReady(p) {
  if (!dataIsLoaded) return
  if (dataIsLoaded && !octoInitialized) {
    for (let i = 0; i < numOctoz; i++) {
      const octo = new Octo(p, collectionData[i])
      octo.setImageData(imageData)
      octo.flipDirection()
      octo.setAttributes(collectionData[i].metadata.attributes)
      {
        const x = octoPositions[i][0]
        const y = octoPositions[i][1]
        octo.setPosition(x, y)
      }
      octo.direction = p.random(1) < 0.5 ? -1 : 1
      octo.createOctoGraphics()
      octo.createOctoImage()
      octoz.push(octo)

      for (let i = 0; i < octoBooleans.length; i++) {
        if (p.random() < 0.5) octoBooleans[i] = true
        else octoBooleans[i] = false
      }
    }

    boatTintCol = p.color(255)

    // bgImgScaled = rectify(p, bgImg, scaleFactor);
    octoInitialized = true
  }
}

function draw(p) {
  return () => {
    p.background(0, 0, 0)
    p.fill(255)
    p.textSize(p.width * 0.05)
    p.textAlign(p.CENTER)
    p.text("loading", p.width / 2, p.height / 2)
    p.cursor(p.ARROW)

    getObjectsReady(p)
    if (!octoInitialized) return

    //------------------------------- TIME
    const dt = p.deltaTime * 0.001
    elapsedTime += dt
    animTime += dt

    // advance keyframe animation
    if (animTime >= animKeys[curAnimKey].duration) {
      curAnimKey++
      animTime = 0
      if (curAnimKey >= animKeys.length - 1) curAnimKey = 0
    }

    //------------------------------- ANIMATE (LERP)
    {
      const dur = animKeys[curAnimKey].duration
      let easeType = animKeys[curAnimKey].easeType
      if (!easeType) easeType = "linear"
      boatX = Math.floor(
        tween(p, animKeys, curAnimKey, "boat.x", ease(animTime / dur, easeType))
      )
    }

    //------------------------------- ANIMATE (SWAP)
    if (animKeys[curAnimKey].name === "boat stop") {
      collectionData = shuffle(collectionData)
      for (let i = 0; i < numOctoz - 1; i++) {
        const octo = octoz[i]
        octo.setTokenData(collectionData[i])
        octo.setAttributes(collectionData[i].metadata.attributes)
        octo.createOctoGraphics()
        octo.createOctoImage()

        for (let i = 0; i < octoBooleans.length; i++) {
          if (p.random() < 0.6) octoBooleans[i] = true
          else octoBooleans[i] = false
        }
      }
    } else if (animKeys[curAnimKey].name === "waiting") {
      const octo = octoz[numOctoz - 1]
      octo.setTokenData(collectionData[numOctoz - 1])
      octo.setAttributes(collectionData[numOctoz - 1].metadata.attributes)
      octo.createOctoGraphics()
      octo.createOctoImage()

      boatTintColIdx = (boatTintColIdx + 1) % boatTintCols.length
      boatTintCol = boatTintCols[boatTintColIdx]
    }

    //------------------------------- DRAWING
    pg.background(0, 0, 0)
    // bg
    pg.push()
    if (timeMode === "night") pg.tint(0, 0, 250)
    pg.image(bgImg, 0, 0)
    pg.pop()
    pg.image(bgSunImg, 0, 0)

    // sun filter
    pg.push()
    pg.blendMode(pg.ADD)
    pg.noStroke()
    if (timeMode === "sunset") {
      pg.drawingContext.filter = `blur(9px)`
      pg.fill(255, 220)
      pg.ellipse(169, 61, 50, 50)
    } else {
      pg.tint(255, 255, 0)
      pg.image(bgSunImg, 0, 0)
    }
    pg.pop()

    pg.push()
    if (timeMode === "night") pg.tint(0, 0, 250)
    pg.image(bgCloudImg, 0, 0)
    pg.pop()

    // reflection
    playAnimArray(pg, reflectionImgs, 0, 90, 6)
    playAnimArray(pg, frontReflectionImgs, 0, 134, 9)

    pg.push()
    pg.blendMode(pg.ADD)
    pg.drawingContext.filter = `blur(1px)`
    playAnimArray(pg, reflectionImgs, 0, 90, 6)
    playAnimArray(pg, frontReflectionImgs, 0, 134, 9)
    pg.pop()
    // bus stop
    pg.image(stationImg, 0, 0)

    // octoz (3 waiting)
    for (let i = 0; i < numOctoz - 1; i++) {
      const oc = octoz[i]
      if (octoBooleans[i] === true) oc.display(pg)
    }
    // boat
    pg.push()
    pg.translate(boatX, boatY)
    octoz[numOctoz - 1].display(pg)
    playAnimArray(pg, boatReflectionImgs, 0, 110, 6)
    pg.push()
    pg.tint(boatTintCol)
    if (timeMode === "night") pg.tint(100, 0, 250)
    pg.image(boatFillImg, 0, 0)
    pg.pop()
    pg.image(boatLineImg, 0, 0)
    pg.pop()

    // timeMode button
    pg.push()
    const hovered = checkHover(p, buttonObj, p.mouseX, p.mouseY, factor)
    if (hovered) {
      p.cursor(p.HAND)
      pg.tint(0, 255, 0)
    }
    pg.image(buttonImg, buttonObj.hoverX, buttonObj.hoverY)
    pg.pop()

    // render everything
    p.push()
    p.image(pg, 0, 0, p.width, p.height)
    p.pop()

    // if (displayDialogue) {
    //   activeOcto.displayDialogue(pg)
    // }

    //------------------------------- SAVE
    if (saveImage) {
      const img = scaleUpAll(p, [pg], 8, origWidth, origHeight)
      img.save(projectTitle)
      saveImage = false
    }
  }
}

/**
 * sprite animation from an array of p5.Image
 * @param {Object} pg
 * @param {Array} arr
 * @param {Number} x
 * @param {Number} y
 * @param {Number} speed per second
 */
function playAnimArray(pg, arr, x, y, speed) {
  pg.push()
  pg.translate(x, y)
  // TODO: self-updating internal counter?
  pg.image(arr[Math.floor(elapsedTime * speed) % arr.length], 0, 0)
  pg.pop()
}

/**
 * tween(): returns interpolated value at t
 * @param {PGraphic} p
 * @param {Array} animKeys array of keyframes
 * @param {Number} keyIdx idx in animKeys array
 * @param {String} objKey which prop to animate
 * @param {Number} t lerp amount
 * @returns Number
 */
function tween(p, animKeys, keyIdx, objKey, t) {
  if (!animKeys[keyIdx + 1]) return
  const startVal = _.get(animKeys[keyIdx], objKey)
  const endVal = _.get(animKeys[keyIdx + 1], objKey)
  return p.lerp(startVal, endVal, t)
}

// all interactable objects must have same fields (obj.hoverX/Y/W/H)
function checkHover(p, obj, mx, my, factor) {
  // console.log(Math.floor(mx / factor), Math.floor(my / factor));
  if (
    mx / factor >= obj.hoverX &&
    mx / factor < obj.hoverX + obj.hoverW &&
    my / factor >= obj.hoverY &&
    my / factor < obj.hoverY + obj.hoverH
  ) {
    return true
  }
  return false
}

function calculateCanvasSize(
  p,
  origWidth,
  origHeight,
  windowWidth,
  windowHeight
) {
  let w, h
  if (windowWidth > windowHeight) {
    factor = Math.floor((windowHeight / origHeight) * 1)
    h = origHeight * factor
    w = h * (origWidth / origHeight)
    // scaleFactor = hFactor;
  } else {
    factor = Math.floor((windowWidth / origWidth) * 1)
    w = origWidth * factor
    h = w * (origHeight / origWidth)
    // scalefactor = wFactor;
  }

  w = Math.max(w, origWidth)
  h = Math.max(h, origHeight)

  return { w, h }
}

// mousePressed has a double clicking "bug" on mobile, so use released instead.
function mouseReleased(p) {
  return () => {
    const hovered = checkHover(p, buttonObj, p.mouseX, p.mouseY, factor)
    if (hovered) {
      if (timeMode === "sunset") timeMode = "night"
      else timeMode = "sunset"
    }
  }
}

function touchStarted() {
  return false
}
function touchMoved() {
  return false
}
function touchEnded(p) {
  mouseReleased(p)
  // prevent default
  return false
}

function keyTyped(p) {
  return () => {
    if (p.key === "s") {
      saveImage = true
    }
    if (p.key === " ") {
      if (timeMode === "sunset") timeMode = "night"
      else timeMode = "sunset"
    }
  }
}

function windowResized(p, props) {
  return () => {
    // const dim = calculateCanvasSize(p, factor, origWidth, origHeight)
    const dim = calculateCanvasSize(
      p,
      origWidth,
      origHeight,
      p.windowWidth,
      p.windowHeight
    )
    p.resizeCanvas(dim.w, dim.h)
  }
}
